import React, { useState, useEffect } from "react";
import axios from "axios";
import { contactMessageRoute } from "../utils/APIRoutes";
import styled from "styled-components";
import Logout from "./Logout";
import Logo from "../assets/logo.svg";

export default function Contacts({ contacts, changeChat, socket, user }) {
  const [currentUserName, setCurrentUserName] = useState(undefined);
  const [currentUserImage, setCurrentUserImage] = useState(undefined);
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const [lastMessageSocket, setLastMessageSocket] = useState(undefined);
  const [lastMessageDB, setLastMessageDB] = useState(undefined);

  useEffect(async () => {
    async function fetchData() {
      const data = await axios.get(`${contactMessageRoute}`);
      setLastMessageDB(data.data)
    }
    fetchData();
  },[]);

  useEffect(async () => {
    async function fetchData() {
      const data = await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );
      setCurrentUserName(data.username);
      setCurrentUserImage(data.avatarImage);
    }
    fetchData();
  }, []);
  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
  };

  useEffect(() => {
    console.log(socket.current)
    async function fetchData() {
      if (socket.current) {
        socket.current.on("upd-receive", (msg) => {
          setLastMessageSocket(msg)
        });
      }
    }
    fetchData();
  });

  return (
    <>
      {currentUserImage && currentUserImage && (
        <Container>
          <div className="brand">
            <img src={Logo} alt="logo" />
            <h3>Chat Interno BETA</h3>
          </div>
          <div className="contacts">
            {contacts.map((contact, index) => {
              return (
                <div
                  key={contact._id}
                  className={`contact ${
                    index === currentSelected ? "selected" : ""
                  }`}
                  onClick={() => changeCurrentChat(index, contact)}
                >
                  <div className="avatar">
                    <img
                      src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                      alt=""
                    />
                  </div>
                  <div className="username">
                    <h3>{contact.username}</h3>
                    <div className="msg"><p>{(lastMessageDB !== undefined) && lastMessageDB.filter(msg => msg.sender === contact._id && msg.users[1] === user._id).map(msg => msg.message.text).pop()}</p></div>
                    <div className="msg"><p>{(lastMessageDB !== undefined) && lastMessageDB.filter(msg => msg.sender === contact._id && msg.users[1] === user._id).map(msg => new Date(msg.createdAt).toLocaleString('pt-BR')).pop()}</p></div>
                  </div>
                  {/* <div><h4>{(lastMessageSocket !== undefined) && (lastMessageSocket.from === contact._id ) && lastMessageSocket.msg}</h4></div> */}
                  {/* <div><h4>{lastMessageDB.filter(msg => msg.sender === contact._id && msg.users[1] === user._id).map(msg => msg.message.text).pop()}</h4></div> */}
                  <div><h4>{(lastMessageSocket !== undefined) && (lastMessageSocket.from === contact._id ) && '🔔'}</h4></div>
                </div>
              );
            })}
          </div>
          <div className="current-user">
            <div className="avatar">
              <img
                src={`data:image/svg+xml;base64,${currentUserImage}`}
                alt="avatar"
              />
            </div>
            <div className="username">
              <h2>{currentUserName}</h2>
            </div>
            <Logout />
          </div>
        </Container>
      )}
    </>
  );
}
const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 75% 15%;
  overflow: hidden;
  background-color: rgba(255,255,255,0.8);
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 2rem;
    }
    h3 {
      color: black;
      text-transform: uppercase;
    }
  }
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.8rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      background-color: #ffffff;
      min-height: 5rem;
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      transition: 0.5s ease-in-out;
      .msg {
        font-size:10px;
      }
      .avatar {
        display: none;
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: black;
        }
      }
    }
    .selected {
      background-color: #DCF8C6;
    }
  }

  .current-user {
    background-color: rgba(255,255,255,0.6);;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .avatar {
      display: none;
      img {
        height: 4rem;
        max-inline-size: 100%;
      }
    }
    .username {
      h2 {
        color: black;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
`;
