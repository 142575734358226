import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ZDG from "../assets/logo.svg";
import background from "../assets/wa-background.png";
export default function Welcome() {
  const [userName, setUserName] = useState("");
  useEffect(async () => {
    setUserName(
      await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      ).username
    );
  }, []);
  return (
    <Container>
      <img src={ZDG} alt="" />
      <h1>
        Seja bem vindo, <span>{userName}!</span>
      </h1>
      <h3>Selecione um usuário para começar a conversar.</h3>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-image: url("${background}");
  justify-content: center;
  align-items: center;
  color: black;
  flex-direction: column;
  img {
    height: 20rem;
  }
  span {
    color: #2576d2;
  }
`;
